<template>
    <div>
        <div class="alert alert-danger mt-1 alert-validation-msg" v-for="error in errors">
                <div class="alert-body d-flex align-items-center">
                    <span>{{ error }}</span>
                </div>
            </div>

        <div class="form-group mb-3">
            <label for="">Title</label>
            <input type="text" class="form-control form-control-lg" v-model="formData.title">
        </div>

        <div class="form-group mb-3">
            <label for="">Description</label>
            <input type="text" class="form-control form-control-lg" v-model="formData.description">
        </div>

        <div class="form-group mb-3">
            <label for="">Slug</label>
            <input type="text" class="form-control form-control-lg" v-model="formData.slug">
        </div>

        <div class="form-group mb-3">
            <label for="">Image</label>
            <input type="file" class="form-control form-control-lg" id="file" ref="file"
                v-on:change="handleFileUpload($event, index, i)">
            <img :src="formData.image" class="my-5">
        </div>

        <div class="form-group mb-3">

            <label for="">Content</label>
            <quill-editor
                theme="snow"
                style="height: 400px"
                toolbar="full"
                v-model:content="formData.content"
                content-type="html"
                >
            </quill-editor>
        </div>

        <div class="form-group mb-3">
            <button type="button" class="btn btn-primary" v-if="loading">
                        <div class="spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                    <button type="button" class="btn btn-primary" v-else @click="validateData">Save</button>
        </div>

    </div>
</template>

<script>

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import axios from 'axios';

export default {
    components: {QuillEditor},
    data(){
        return {
            formData: {
                title: '',
                description: '',
                slug: '',
                image: '',
                content: '',
            },
            errors: [],
            loading: false,
            file_upload_msg: '',
        };
    },

    methods: {
        handleFileUpload(event) {
            let file = event.target.files[0];
            let formData = new FormData();
            formData.append('file', file);
            let headers = { 'Content-Type': 'multipart/form-data' };
            axios.post('/articles/upload-file', formData, { headers: headers }).then((response) => {
                console.log(response.data);
                this.formData.image = response.data;
                this.errors = [];
            }).catch((e) => {
                for (const [key, value] of Object.entries(e.response.data.errors)) {
                    this.errors.push(`${value}`);
                }
            });
        },
        validateData(){
            this.errors = [];
            if(this.formData.title == '') {
                this.errors.push('Please Fill Title'); return;
            }
            if(this.formData.description == '') {
                this.errors.push('Please Fill description'); return;
            }
            if(this.formData.slug == '') {
                this.errors.push('Please Fill slug'); return;
            }
            if(this.formData.image == '') {
                this.errors.push('Please Fill image'); return;
            }
            if(this.formData.content == '') {
                this.errors.push('Please Fill content'); return;
            }
            this.storeData();
        },
        storeData(){
            this.loading = true;
            let url = "/articles/store";

            let formData = new FormData();
            formData.append("title", this.formData.title);
            formData.append("description", this.formData.description);
            formData.append("slug", this.formData.slug);
            formData.append("image", this.formData.image);
            formData.append("content", this.formData.content);

            axios
                .post(url, formData, { headers: this.headers })
                .then((response) => {

                    setTimeout(() => {
                        window.location.href = response.data;
                    }, 1000);
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                    // for (const [key, value] of Object.entries(
                    //     e.response.data.errors
                    // )) {
                    //     this.errors.push(`${value}`);
                    // }
                });
        },
    }

}
</script>


<style scoped>
.spinner-border{
    width: 17px;
    height: 17px;
}
</style>
